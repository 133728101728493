
// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
 
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoIconDark : logoIcon} alt="icon logo" width="100" />
     *
     */
<svg width="116" height="32" viewBox="0 0 116 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M57.0563 19.9308C54.394 17.2667 54.394 12.9303 57.0563 10.2662L67.1814 0.132324L71.8652 4.8194L61.5941 15.0976L71.8652 25.3759L67.1814 30.0629L57.0563 19.9308Z" fill="#FD7E14"/>
<path d="M15.9615 32C11.695 31.9845 7.68445 30.3105 4.67181 27.2854C1.65918 24.2604 0 20.2402 0 15.9673V1.76345H6.62298V15.9673C6.62298 21.1529 10.8396 25.3724 16.0216 25.3724C18.5327 25.3724 20.8926 24.3945 22.6669 22.6173C24.4429 20.8418 25.4202 18.4802 25.4202 15.9673V10.5618C25.4202 6.97991 27.2116 3.67129 30.2122 1.71189C31.9263 0.591256 33.9187 0 35.9747 0C37.2491 0 38.4927 0.225158 39.6743 0.666882C41.0072 1.16704 42.2095 1.91986 43.2452 2.90643L50.5999 10.2662C53.2621 12.9303 53.2621 17.2667 50.5999 19.9308L40.4747 30.063L35.7909 25.3759L46.062 15.0976L38.8619 7.89258C38.7915 7.81523 38.7142 7.73789 38.6352 7.6657C37.9087 6.99538 36.964 6.62757 35.9747 6.62757C33.8071 6.62757 32.0431 8.39274 32.0431 10.5618V15.8402C32.0431 17.8116 31.6859 19.7452 30.9834 21.5843C29.8292 24.6024 27.8248 27.1875 25.1849 29.0626C22.4797 30.9842 19.3107 32 16.0216 32H15.9632H15.9615Z" fill="#FD7E14"/>
<path d="M79.5703 9.39136H81.4149V14.173C82.1449 13.1503 83.5241 12.602 85.0854 12.602C87.5587 12.602 89.5253 13.9787 89.5253 16.7494V22.4488H87.6996V16.978C87.6996 15.0014 86.4629 13.9959 84.5976 13.9959C82.7323 13.9959 81.4132 15.0186 81.4132 17.1017V22.4488H79.5685V9.39136H79.5703Z" fill="white"/>
<path d="M102.016 22.4488H100.171V21.0188C99.4615 22.0243 98.1029 22.5898 96.6224 22.5898C94.0666 22.5898 92.0605 21.213 92.0605 18.4424V12.7791H93.8863V18.231C93.8863 20.1904 95.1229 21.1959 96.9882 21.1959C98.8535 21.1959 100.173 20.1732 100.173 18.09V12.7791H102.017V22.4488H102.016Z" fill="white"/>
<path d="M110.621 12.6209C113.621 12.6209 115.894 14.5614 115.894 17.5796C115.894 20.5977 113.602 22.6087 110.621 22.6087C108.592 22.6087 107.153 21.7269 106.464 20.6682V22.4505H104.619V9.39136H106.464V14.5786C107.173 13.5026 108.654 12.6192 110.621 12.6192V12.6209ZM110.236 14.0148C108.228 14.0148 106.464 15.3555 106.464 17.5968C106.464 19.838 108.228 21.1958 110.236 21.1958C112.244 21.1958 114.008 19.8552 114.008 17.5778C114.008 15.3005 112.283 14.0131 110.236 14.0131V14.0148Z" fill="white"/>
</svg>

  );
};

export default LogoIcon;
